import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";

// Contexts
import { useRealmAuth } from "../providers/realm-auth.provider";

// Custom Components
import {
  ProtectedRoute,
  ProtectedRouteProps,
} from "./protected-route.component";

// Pages
import SignInAndSignUpPage from "../pages/sign-in-and-sign-up.component";
import PageNotFound from "../pages/page-not-found.component";
import SettingsPage from "../pages/settings.component";
import HomePage from "../pages/home.component";

const DebugRouter = ({ children }: { children: any }) => {
  const { location } = useHistory();
  if (process.env.NODE_ENV === "development") {
    console.log(
      `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
        location.state
      )}`
    );
  }

  return children;
};

const PageRouter = () => {
  const { isLoggedIn } = useRealmAuth();

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: isLoggedIn,
    isAllowed: isLoggedIn,
    authenticationPath: "/signin",
    restrictedPath: "/signin",
  };

  return (
    <DebugRouter>
      <Switch>
        <Route exact path="/signin" component={SignInAndSignUpPage} />
        <ProtectedRoute
          {...defaultProtectedRouteProps}
          exact
          path="/settings"
          component={SettingsPage}
        />
        <ProtectedRoute
          {...defaultProtectedRouteProps}
          path="/"
          component={HomePage}
        />
        <Route component={PageNotFound} />
      </Switch>
    </DebugRouter>
  );
};

export default PageRouter;
