import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import MenuItem from '@material-ui/core/MenuItem';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import { useRealmAuth } from '../providers/realm-auth.provider';

import DropDownButton from './dropdown-button.component';

const iconStyle = { marginRight: 4 };

const AccountMenuButton = () => {
  const {
    currentUser,
    actions: { handleLogout },
  } = useRealmAuth();

  return (
    <DropDownButton
      label={currentUser?.profile.firstName || 'Unknown User'}
      startIcon={<PersonOutlineOutlinedIcon />}
    >
      <MenuItem component={RouterLink} to="/settings">
        <SettingsOutlinedIcon style={iconStyle} /> Settings
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <MeetingRoomOutlinedIcon style={iconStyle} /> Logout
      </MenuItem>
    </DropDownButton>
  );
};

export default AccountMenuButton;
