import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Tile from "../components/tile.component";
import { getTiles } from "../realm/webooks";
import { SearchContext } from "../providers/search.provider";

export default function Home() {
  const [data, setData] = useState<any>();
  const [tiles, setTiles] = useState<any>();
  const { search } = useContext(SearchContext);

  useEffect(() => {
    getTiles().then((result) => setData(result));
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      //search for keywords and title
      const filteredData = data.filter(
        (tile: any) =>
          tile.title.toLowerCase().includes(search.toLowerCase()) ||
          tile.tags.includes(search)
      );
      setTiles(filteredData);
    } else {
      setTiles(data);
    }
  }, [data, search]);

  return (
    <Grid
      spacing={2}
      alignContent="space-around"
      justifyContent="space-evenly"
      container
    >
      {tiles &&
        tiles.map((tile: any, index: number) => (
          <Tile
            kb={tile.kb}
            key={`${tile.title}-${index}`}
            title={tile.title}
            tags={tile.tags}
            image={tile.logo}
            desc={tile.desc}
            owners={tile.owners}
            summary={tile.summary}
            url={tile.url}
          />
        ))}
    </Grid>
  );
}
