import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";

// Material UI
import { Grid, Button, Divider } from "@material-ui/core";
import ComputerOutlinedIcon from "@material-ui/icons/Computer";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

// Custom Components
import AccountMenuButton from "./account-menu-button.component";

// Contexts
import { useRealmAuth } from "../providers/realm-auth.provider";
import { SearchContext } from "../providers/search.provider";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Header = () => {
  const { currentUser } = useRealmAuth();
  const classes = useStyles();
  const { search, setSearch } = useContext(SearchContext);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container xs={12} justifyContent="center">
        <Grid item container alignItems="center" justifyContent="center">
          <img
            alt="MongoDB Logo"
            src="Logomark_RGB_Forest-Green.png"
            style={{ width: "80px" }}
          />
          <h1>mSIDE Portal</h1>
        </Grid>
      </Grid>
      {currentUser && (
        <Grid item container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<ComputerOutlinedIcon />}
              component={RouterLink}
              to="/"
            >
              Home
            </Button>
          </Grid>
          <Grid item className="tour-user-profiler">
            <AccountMenuButton />
          </Grid>
          <Grid item>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                value={search}
                onChange={setSearch}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Header;
