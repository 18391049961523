const baseURL = `https://webhooks.mongodb-stitch.com/api/client/v2.0/app/${process.env.REACT_APP_MSIDE_AUTH}/service/http/incoming_webhook`;
//const secret = `6r0ZQil72dsmi2SpOoYvE5MzWR5jNt3t`;
// eslint-disable-next-line @typescript-eslint/naming-convention
type uid = string | undefined;
type email = string | undefined;

export const setCustomUserData = (uid: uid, data: object) => {
  return fetchHelper(`${baseURL}/setCustomUserData`, uid, data);
};

/// Helper Functions
const fetchHelper = (url: string, uid?: uid, data?: object) => {
  const params = {
    method: data ? "post" : "get",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      ...(uid && { Authorization: uid }),
    },
    ...(data && { body: JSON.stringify(data) }),
  };

  return fetch(url, params).then((response) => response.json());
};

export const getTiles = () => {
  return fetchHelper(
    `https://us-east-1.aws.data.mongodb-api.com/app/portal-ylyru/endpoint/getTiles`
  );
};
