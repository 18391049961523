import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { RealmAuthProvider } from "./providers/realm-auth.provider";
import CustomThemeProvider from "./providers/custom-theme.provider";
import SearchProvider from "./providers/search.provider";

ReactDOM.render(
  <CustomThemeProvider>
    <RealmAuthProvider>
      <SnackbarProvider maxSnack={3}>
        <SearchProvider>
          <Router>
            <App />
          </Router>
        </SearchProvider>
      </SnackbarProvider>
    </RealmAuthProvider>
  </CustomThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
