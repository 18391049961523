import React, { useState, useEffect } from "react";

// Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid, CircularProgress, useTheme } from "@material-ui/core";

// Custom Components
import Header from "./components/header.component";
import Footer from "./components/footer.component";
import PageRouter from "./components/page-router.component";

function App() {
  const [isAppLoading, setIsAppLoading] = useState(true);

  const theme = useTheme();

  useEffect(() => {
    // arbitrary wait for Realm Auth to initiate
    setTimeout(() => {
      setIsAppLoading(false);
    }, 1000);
  }, []);

  return (
    <div
      className="App"
      style={{ backgroundColor: theme.palette.background.default }}
    >
      <CssBaseline />
      <Grid
        style={{ height: "100vh", width: "100%", marginLeft: "0px" }}
        container
        spacing={2}
      >
        <Grid
          item
          container
          xs
          sm
          direction="column"
          spacing={2}
          justifyContent="space-between"
          style={{ marginLeft: "0px" }}
        >
          <Grid item container>
            <Grid item sm={12}>
              <Header />
            </Grid>
          </Grid>
          <Grid item container xs>
            <Grid item container justifyContent="center">
              {isAppLoading ? <CircularProgress /> : <PageRouter />}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item sm={12} style={{ width: "inherit" }}>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
