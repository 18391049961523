import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { CustomThemeContext } from "../providers/custom-theme.provider";
import {
  Avatar,
  CardHeader,
  CardMedia,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: "600px",
    },
    media: {
      margin: theme.spacing(2),
      height: 140,
    },
  })
);

interface Props {
  open: boolean;
  title: string;
  tags: string[];
  owners: any;
  image: any;
  desc: string;
  kb: string;
  setOpen: (open: boolean) => void;
  handleClickOpen: () => void;
}

const TileDescription: React.FC<Props> = ({
  open,
  setOpen,
  title,
  tags,
  image,
  desc,
  owners,
  kb,
  handleClickOpen,
}) => {
  const theme = useTheme();
  const { currentTheme } = useContext(CustomThemeContext);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent className={classes.dialog}>
        <CardMedia
          className={classes.media}
          image={currentTheme === "dark" ? image.dark : image.light}
          title={title}
        />
        <Typography variant="subtitle1" color="textSecondary" component="p">
          {tags.map((tag, index) => (
            <span key={index}>{tag} </span>
          ))}
        </Typography>
        <p />
        <Typography variant="body1" component="p">
          {desc}
        </Typography>
        <p />
        {kb && (
          <Link target="_blank" href={kb}>
            Additional Information
          </Link>
        )}
        <p />
        {owners.map((owner: any, index: number) => (
          <CardHeader
            key={`card-${index}`}
            avatar={<Avatar alt={owner.email} src={owner.img} />}
            title={
              <Link
                target="_blank"
                key={`owner-${index}`}
                href={"mailto:" + owner.email}
              >
                {owner.email}{" "}
              </Link>
            }
          ></CardHeader>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickOpen} color="primary" autoFocus>
          Open
        </Button>
        <Button autoFocus onClick={handleClose} color="primary">
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TileDescription;
