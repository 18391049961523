import React, { useState } from "react";

export const SearchContext = React.createContext({
  search: "",
  setSearch: (value: any) => {},
});

const SearchProvider: React.FC = (props) => {
  const { children } = props;

  // State to hold the selected theme name
  const [search, setSearch] = useState("");

  const setSearchValue = (event: any) => {
    setSearch(event.target.value);
  };

  const contextValue = {
    search: search,
    setSearch: setSearchValue,
  };

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
