import React, { useContext, useState } from "react";

// Material UI
import {
  Grid,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  CardActions,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TileDescription from "./tile-description.component";
import { CustomThemeContext } from "../providers/custom-theme.provider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 320,
      height: 300,
    },
    media: {
      margin: theme.spacing(2),
      height: 80,
    },
  })
);

interface Props {
  title: string;
  tags: string[];
  owners: any;
  image: any;
  desc: string;
  kb: string;
  url: string;
  summary: string;
}

const OpenWindow = (link: string) => {
  window.open(link, "_blank");
};

const Tile: React.FC<Props> = ({
  title,
  tags,
  image,
  desc,
  owners,
  summary,
  url,
  kb,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { currentTheme } = useContext(CustomThemeContext);

  const handleClickOpen = () => {
    OpenWindow(url);
  };

  const handleClickDesc = () => {
    setOpen(true);
  };

  return (
    <>
      <TileDescription
        kb={kb}
        title={title}
        tags={tags}
        owners={owners}
        image={image}
        desc={desc}
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
      />
      <Grid item>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={currentTheme === "dark" ? image.dark : image.light}
              title={title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {tags.map((tag, index) => (
                  <span key={`tag-${index}`}>{tag} </span>
                ))}
              </Typography>
              <Typography variant="subtitle1">{summary}</Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button onClick={handleClickOpen} size="small" color="primary">
              Open
            </Button>
            <Button onClick={handleClickDesc} size="small" color="primary">
              Learn More
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

export default Tile;
